html, body, #root, #supertokens-root {
    height: 100%;
}

.taskListWrapper {
    display: table;
    border: 1px solid  red;
  }
  
  .taskListTableRow {
    display: table-row;
    text-overflow: ellipsis;
    border: 1px solid  blue;
  }
  
  .taskListTableRow:nth-of-type(even) {
    background-color: #f5f5f5;
  }
  
  .taskListCell {
    display: table-cell;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid  green;
  }
  .taskListNameWrapper {
    display: flex;
    border: 1px solid  orange;
  }
  
  .taskListExpander {
    color: rgb(86 86 86);
    font-size: 0.6rem;
    padding: 0.15rem 0.2rem 0rem 0.2rem;
    user-select: none;
    cursor: pointer;
    border: 1px solid  black;
  }
  .taskListEmptyExpander {
    font-size: 0.6rem;
    padding-left: 1rem;
    user-select: none;
  }

  .ganttTable {
    display: table;
    // border-bottom: #e6e4e4 1px solid;
    // border-top: #e6e4e4 1px solid;
    // border-left: #e6e4e4 1px solid;
    border: 1px solid  aqua;
  }
  
  .ganttTable_Header {
    display: table-row;
    list-style: none;
    border: 1px solid  purple;
  }
  
  .ganttTable_HeaderSeparator {
    border-right: 1px solid rgb(196, 196, 196);
    opacity: 1;
    margin-left: -2px;
    border: 1px solid  lime;
  }
  
  .ganttTable_HeaderItem {
    display: table-cell;
    vertical-align: -webkit-baseline-middle;
    vertical-align: middle;
    border: 1px solid  brown;
  }