/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
	font-family: 'Euclid Circular B';
	font-style: normal;
	font-weight: 300;
	src: local('Euclid Circular B Light'), url('../fonts/Euclid Circular B Light.woff') format('woff');
}
@font-face {
	font-family: 'Euclid Circular B';
	font-style: italic;
	font-weight: 300;
	src: local('Euclid Circular B Light Italic'), url('../fonts/Euclid Circular B Light Italic.woff') format('woff');
}
@font-face {
	font-family: 'Euclid Circular B';
	font-style: normal;
	font-weight: 400;
	src: local('Euclid Circular B Regular'), url('../fonts/Euclid Circular B Regular.woff') format('woff');
}
@font-face {
	font-family: 'Euclid Circular B';
	font-style: italic;
	font-weight: 400;
	src: local('Euclid Circular B Italic'), url('../fonts/Euclid Circular B Italic.woff') format('woff');
}
@font-face {
	font-family: 'Euclid Circular B';
	font-style: normal;
	font-weight: 500;
	src: local('Euclid Circular B Medium'), url('../fonts/Euclid Circular B Medium.woff') format('woff');
}
@font-face {
	font-family: 'Euclid Circular B';
	font-style: italic;
	font-weight: 500;
	src: local('Euclid Circular B Medium Italic'), url('../fonts/Euclid Circular B Medium Italic.woff') format('woff');
}
@font-face {
	font-family: 'Euclid Circular B';
	font-style: normal;
	font-weight: 600;
	src: local('Euclid Circular B SemiBold'), url('../fonts/Euclid Circular B SemiBold.woff') format('woff');
}
@font-face {
	font-family: 'Euclid Circular B';
	font-style: italic;
	font-weight: 600;
	src: local('Euclid Circular B SemiBold Italic'), url('../fonts/Euclid Circular B SemiBold Italic.woff') format('woff');
}
@font-face {
	font-family: 'Euclid Circular B';
	font-style: normal;
	font-weight: 700;
	src: local('Euclid Circular B Bold'), url('../fonts/Euclid Circular B Bold.woff') format('woff');
}
@font-face {
	font-family: 'Euclid Circular B';
	font-style: italic;
	font-weight: 700;
	src: local('Euclid Circular B Bold Italic'), url('../fonts/Euclid Circular B Bold Italic.woff') format('woff');
}